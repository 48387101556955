import React from 'react';
import { Style, Icon, Stroke } from 'ol/style';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { createXYZ } from 'ol/tilegrid';
import { get as getProj } from 'ol/proj';
import MVT from 'ol/format/MVT';
import t from 'i18n';
import shipIconUrl from 'img/icons/shared/shipMarker.svg?url';
import { ShipPopup } from './ShipPopup';
import { Circle as CircleStyle } from 'ol/style';
import { observable } from 'mobx';
import { ShipTrackLayer } from './ShipTrackLayer';

const TILE_SIZE = 512;

export class ShipsLayer {
	@observable exludeShip = null;
	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.shipTrackLayer = new ShipTrackLayer(this.mapStore);
		this.source = new VectorTileSource({
			format: new MVT(),
			tileGrid: createXYZ({
				extent: getProj('EPSG:3857').getExtent(),
				maxZoom: 18,
				tileSize: TILE_SIZE,
			}),
			tileUrlFunction: this.tileUrlFunction,
			tileLoadFunction: this.tileLoadFunction(this.renderPopup),
		});

		this.layer = new VectorTileLayer({
			source: this.source,
			style: this.getStyle,
		});

		this.layer.setZIndex(3);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
		// не убирать
		this.source.refresh();
	};
	getStyle = (feature) => {
		const { record } = feature.getProperties();
		return [
			new Style({
				image: new Icon({
					src: shipIconUrl,
					rotateWithView: true,
					scale: 0.8,
					rotation: (Math.PI / 180) * Number(record.course),
					anchor: [5, 12.5],
					anchorXUnits: 'pixels',
					anchorYUnits: 'pixels',
				}),
			}),
			// new Style({
			// 	image: new CircleStyle({
			// 		radius: 10,

			// 		stroke: new Stroke({ color: '#d90d90', width: 2 }),
			// 	}),
			// }),
		];
	};

	tileUrlFunction = (coords) => {
		let filters = [];

		if (this.exludeShip) {
			filters.push(`id!=${this.exludeShip.id}`);
		}

		const filter = '&filter=' + filters.join(' and ');
		return `/api/mvt?model=Ship&x=${coords[1]}&y=${coords[2]}&z=${coords[0]}${filter}&noCache=1&columns=id,speed,course&buffer=${TILE_SIZE}`;
	};

	tileLoadFunction = (render) => (tile, url) => {
		tile.setLoader(function (extent, resolution, projection) {
			fetch(url).then(function (response) {
				response.arrayBuffer().then(function (data) {
					const format = tile.getFormat(); // ol/format/MVT configured as source format
					const renderFeatures = format.readFeatures(data, {
						extent: extent,
						featureProjection: projection,
					});
					renderFeatures.forEach((f) => {
						const record = f.getProperties();
						f.properties_ = {
							title: t('ship.title') + ' №' + record.id,
							record,
							render,
						};
					});

					tile.setFeatures(renderFeatures);
				});
			});
		});
	};

	setVisible = () => this.layer.setVisible(this.settings.show);
	renderPopup = (data) => <ShipPopup record={data} showTrack={this.showTrack} />;
	showTrack = (ship) => {
		this.mapStore.updatePopup(null);
		this.exludeShip = ship;
		this.source.refresh();
		if (!this.shipTrackLayer.isInitialized) this.shipTrackLayer.initLayer();
		this.shipTrackLayer.updateShip(ship);
		this.shipTrackLayer.updateLayer();
	};
}
