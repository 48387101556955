import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Row, Table, Column, Tabs, Tab } from '@smartplatform/ui';
import store from 'client/store';
import { LabelField, FavouriteButton, NavigationButton } from 'components';
import t from 'i18n';
import { format } from 'date-fns';
import './shipPopup.scss';

@observer
export class ShipPopup extends React.Component {
	@observable record = null;
	@observable trackCount = 0;
	@observable tab = 'mainInfo';

	setTab = (tab) => (this.tab = tab);

	componentDidMount = async () => {
		this.trackCount = await store.model.ShipTrack.count({ shipId: this.props.record.id });
		console.log('👽  ~ 	this.trackCount:', this.trackCount);
		this.record = await store.model.Ship.findById(this.props.record.id, {
			include: [{ relation: 'ladingBills', scope: { include: [{ relation: 'destinationCity', scope: { fields: ['id', 'name'] } }] } }],
		});
	};

	toggleFavourite = async () => {
		this.record.isFavourite = !this.record.isFavourite;
		await this.record.save();
	};

	render() {
		if (!this.record) return null;
		const ladingBills = this.record.ladingBills?.() || [];
		const imageSrc = this.record.image ? this.record.downloadFile('image') + '?image=[{"resize":{"width":400}}]' : null;
		return (
			<div className='ship-popup'>
				{imageSrc ? <img alt='ship' src={imageSrc} className='image' /> : <div className='no-image'>{t('noImage')}</div>}
				<Tabs type='manual'>
					<Tab title={t('mainInfo')} onClick={() => this.setTab('mainInfo')} />
					<Tab title={t('ship.ladingBillTab')} onClick={() => this.setTab('ladingBill')} />
				</Tabs>
				<div className='ship-tab-content'>
					{this.tab === 'mainInfo' && (
						<>
							<Row>
								<LabelField label={t('name')} children={this.record.name ?? '-'} />
								<LabelField label={t('nameLat')} children={this.record.nameLat ?? '-'} />
							</Row>
							<Row>
								<LabelField label={t('ship.callSign')} children={this.record.callSign ?? '-'} />
								<LabelField label={t('ship.type')} children={this.record.type?.name ?? '-'} />
							</Row>
							<Row>
								<LabelField label={t('ship.imo')} children={this.record.imo ?? '-'} />
								<LabelField label={t('ship.mmsi')} children={this.record.mmsi ?? '-'} />
							</Row>
							<Row>
								<LabelField label={t('ship.marineReg')} children={this.record.marineReg ?? '-'} />
								<LabelField label={t('ship.riverReg')} children={this.record.riverReg ?? '-'} />
							</Row>
							<Row>
								<LabelField label={t('speed')} children={this.record.speed ?? '-'} />
								<LabelField label={t('course')} children={this.record.course ?? '-'} />
							</Row>
							<Row>
								<LabelField
									label={t('ship.receiveDate')}
									children={this.record.receiveDate ? format(new Date(this.record.receiveDate), 'dd.MM.yyyy HH:mm') : '-'}
								/>
								<LabelField label={t('ship.source')} children={this.record.source ?? '-'} />
							</Row>
						</>
					)}
					{this.tab === 'ladingBillTab' && (
						<>
							<h3 style={{ marginBottom: 10 }}>{t('ladingBill.plural')}</h3>
							<Table rows={ladingBills} onRowClick={({ id }) => store.route.push({ path: `/delivery/tracking/${id}` })}>
								<Column property='trackNumber' label={t('tracking.trackNumber')} />
								<Column relation='destinationCity' property='name' label={t('request.destinationCity')} />
							</Table>
						</>
					)}
				</div>
				<div className='controls'>
					<Row>
						<FavouriteButton isFavourite={this.record.isFavourite} onClick={this.toggleFavourite} />
						{this.trackCount > 0 ? <NavigationButton onClick={() => this.props.showTrack(this.record)} text={t('ship.route')} /> : <div />}
					</Row>
				</div>
			</div>
		);
	}
}
