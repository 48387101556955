import React from 'react';
import { observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';
import { DynamicLayers } from '@smartplatform/map/client';
import store from 'client/store';
import module from './index';
import { Contragents, ModuleWrapper } from 'components';
import Map from './map';
import { DICTIONARIES_MODELS, PLAN_CODE, REQUEST_CODE } from './constants';
import loadable from '@loadable/component';
import { isHozSubject, isMO } from 'delivery/helpers/roles';
const DictionariesPage = loadable(() => import('components/dictionaries/dictionaries-page'));
const Audit = loadable(() => import('components/audit'));
const OperInfo = loadable(() => import('./oper-info'));
const Analytics = loadable(() => import('./analytics'));
const Incidents = loadable(() => import('./incidents'));
const Requests = loadable(() => import('./requests'));
const Reserves = loadable(() => import('./reserves'));
const Tracking = loadable(() => import('./tracking'));
const NPA = loadable(() => import('./npa'));
import { Reports } from './reports';
import deliveryStore from './deliveryStore';

@observer
export default class Root extends React.Component {
	constructor(props) {
		super(props);
		if (!deliveryStore.isInitialized) deliveryStore.init();
		store.deliveryStore = deliveryStore;
	}

	render() {
		if (!deliveryStore.isInitialized) return null;
		const { path } = this.props.match;
		return (
			<ModuleWrapper className='delivery-module' module={module} path={path} redirectPath={`${path}/requests`}>
				{isHozSubject() && (
					<Route path={`${path}/requests`} exact>
						<Redirect to={`${path}/plans`} />
					</Route>
				)}
				{isMO() && (
					<Route path={`${path}/plans`} exact>
						<Redirect to={`${path}/requests`} />
					</Route>
				)}
				{isMO() && (
					<Route path={`${path}/oper-info`} exact>
						<Redirect to={`${path}/requests`} />
					</Route>
				)}
				<Route path={`${path}/map`} component={Map} />
				<Route path={`${path}/layers`} render={(routeProps) => <DynamicLayers {...routeProps} store={store} />} />
				<Route path={`${path}/tracking`} component={Tracking} />
				<Route path={`${path}/reports`} render={(props) => <Reports {...props} />} />
				<Route>
					<div className='delivery-page'>
						<Route path={`${path}/requests`} render={(props) => <Requests {...props} code={REQUEST_CODE} />} />
						<Route path={`${path}/plans`} render={(props) => <Requests {...props} code={PLAN_CODE} />} />
						<Route path={`${path}/oper-info`} render={(props) => <OperInfo {...props} />} />
						<Route path={`${path}/journal`} component={Audit} />
						<Route path={`${path}/reserves`} component={Reserves} />
						<Route path={`${path}/incidents`} component={Incidents} />
						<Route path={`${path}/contragents`} render={(props) => <Contragents module='delivery' {...props} />} />
						<Route path={`${path}/analytics`} render={(props) => <Analytics {...props} />} />
						<Route path={`${path}/dictionaries`} render={(props) => <DictionariesPage models={DICTIONARIES_MODELS} {...props} />} />
						<Route path={`${path}/npa`} component={NPA} />
					</div>
				</Route>
			</ModuleWrapper>
		);
	}
}
