import React from 'react';
import { Style, Icon, Stroke } from 'ol/style';
import t from 'i18n';
import shipIconUrl from 'img/icons/shared/shipSelectedMarker.svg?url';
import { Circle as CircleStyle } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { observable } from 'mobx';
import { subMonths } from 'date-fns';
import { drawGeoMarker, geoJSON } from '@smartplatform/map/client';
import { Vector as VectorLayer } from 'ol/layer';
import store from 'client/store';
import { Feature } from 'ol';
import { fromLonLat } from 'ol/proj';

export class ShipTrackLayer {
	@observable ship = null;
	@observable startDate = subMonths(new Date(), 1);
	@observable endDate = new Date();
	@observable selectedDate = new Date();
	@observable isInitialized = false;

	constructor(mapStore) {
		this.mapStore = mapStore;
	}

	updateShip = (ship) => (this.ship = ship);
	updateDate = (date) => (this.selectedDate = date);
	updateLayer = () => this.load();

	initLayer = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(10);
		this.mapStore.addLayer(this.layer);
		this.isInitialized = true;
	};

	load = async () => {
		const { startDate, endDate } = this;

		const tracks = await store.model.ShipTrack.find({
			where: { shipId: this.ship.id, receiveDate: { between: [startDate, endDate] }, geo: { neq: null }, limit: 100, order: 'receiveDate DESC' },
		});

		const features = [];
		for (let track of tracks) {
			const feature = drawGeoMarker(track.geo, {
				// style: this.getStyle(track),
				data: {
					record: track,
				},
			});

			const featureObj = {
				isMarker: true,
				geometry: geoJSON.readGeometry(track.geo),
				title: '123',
				record: track,
			};

			features.push(new Feature(featureObj));
		}
		console.log(features);
		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};

	getStyle = (ship) => {
		return [
			// new Style({
			// 	image: new Icon({
			// 		src: shipIconUrl,
			// 		rotateWithView: true,
			// 		scale: 0.8,
			// 		rotation: (Math.PI / 180) * Number(ship.course),
			// 		anchor: [5, 12.5],
			// 		anchorXUnits: 'pixels',
			// 		anchorYUnits: 'pixels',
			// 	}),
			// }),
			new Style({
				image: new CircleStyle({
					radius: 10,

					stroke: new Stroke({ color: '#d90d90', width: 2 }),
				}),
			}),
		];
	};

	getStyle = (ship) => {
		return [
			new Style({
				image: new Icon({
					src: shipIconUrl,
					rotateWithView: true,
					scale: 0.8,
					rotation: (Math.PI / 180) * Number(ship.course),
					anchor: [5, 12.5],
					anchorXUnits: 'pixels',
					anchorYUnits: 'pixels',
				}),
			}),
			// new Style({
			// 	image: new CircleStyle({
			// 		radius: 10,
			// 		stroke: new Stroke({ color: '#d90d90', width: 2 }),
			// 	}),
			// }),
		];
	};

	timeLineComponent = (<div style={{ height: 300, width: 300 }}>3333</div>);
}
